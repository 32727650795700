<template>
    <div class="flex flex-wrap align-items-center justify-content-center p-fluid">
        <!-- 主頁面 -->
        <Toast />
        <div v-if="statusLogin == 0" class="card">
            <div class="mb-4 mt-4" style="text-align:center;">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
            </div>
        </div>
        <div v-if="statusLogin != 200" class="card">
            <div class="mb-4 mt-4">
                <InlineMessage>{{ statusMessage }}</InlineMessage>
                <Button v-on:click="purchaseMore" label="追加餐點" class="p-button-secondary p-button mr-2 mt-4" />
            </div>
        </div>
        <div v-if="statusLogin == 200" class="card col-12 lg:col-4">
            <div class="grid formgrid text-base">
                <div class="border-round bg-gray-100 w-full">
                    <ul class="step-bar mt-3 align-content-center justify-content-center text-center">
                        <li v-bind:class="{ 'is-active': isOrderSubmitted }" class="step">
                            <span class="step__title">送出訂單</span>
                        </li>
                        <li v-bind:class="{ 'is-active': isOrderReceived }" class="step">
                            <span class="step__title">送達點餐機</span>
                        </li>
                        <li v-bind:class="{ 'is-active': isOrderAccepted }" class="step">
                            <span class="step__title">接受訂單</span>
                        </li>
                        <li v-bind:class="{ 'is-active': isOrderDelivering }" class="step">
                            <span class="step__title">
                                <span v-if="channelValue == '外送'">外送途中</span>
                                <span v-if="channelValue == '自取'">訂單備妥</span>
                            </span>
                        </li>
                    </ul>
                    <div class="w-full my-3 text-center">
                        <span class="text-pink-300">▸&nbsp;</span>
                        <span class="font-bold">{{ statusMessage }}</span>
                        <span class="text-pink-300">&nbsp;◂</span>
                    </div>
                </div>
                <div class="border-bottom-1 border-gray-200 col-12 lg:col-12 lg:mb-0 flex align-items-center flex-wrap">
                    <div class="inline-block col-5">
                        <i class="py-3 pi pi-user" /><span class="ml-3 inline-block py-3">訂購人</span>
                    </div>
                    <div class="inline-block col-7">
                        <span class="inline-block py-3">{{ nameValue }}</span>
                    </div>
                </div>
                <div class="border-bottom-1 border-gray-200 col-12 lg:col-12 lg:mb-0 flex align-items-center flex-wrap">
                    <div class="inline-block col-5">
                        <i class="py-3 pi pi-map-marker" /><span class="ml-3 inline-block py-3">外送地址</span>
                    </div>
                    <div class="inline-block col-7">
                        <span class="inline-block py-3">{{ addressValue }}</span>
                    </div>
                </div>
                <div class="border-bottom-1 border-gray-200 col-12 lg:col-12 lg:mb-0 flex align-items-center flex-wrap">
                    <div class="inline-block col-5">
                        <i class="py-3 pi pi-phone" /><span class="ml-3 inline-block py-3">手機號碼</span>
                    </div>
                    <div class="inline-block col-7">
                        <span class="inline-block py-3">{{ phoneValue }}</span>
                    </div>
                </div>
                <div class="border-bottom-1 border-gray-200 col-12 lg:col-12 lg:mb-0 flex align-items-center flex-wrap">
                    <div class="inline-block col-5">
                        <i class="py-3 pi pi-id-card" /><span class="ml-3 inline-block py-3">統一編號</span>
                    </div>
                    <div class="inline-block col-7">
                        <span class="inline-block py-3">{{ companyidValue }}</span>
                    </div>
                </div>
                <div class="border-bottom-1 border-gray-200 col-12 lg:col-12 lg:mb-0 flex align-items-center flex-wrap">
                    <div class="inline-block col-5">
                        <i class="py-3 pi pi-comment" /><span class="ml-3 inline-block py-3">給店家的話</span>
                    </div>
                    <div class="inline-block col-7">
                        <span class="inline-block py-3">{{ commentValue }}</span>
                    </div>
                </div>
                <div class="border-bottom-1 border-gray-200 col-12 lg:col-12 lg:mb-0 flex align-items-center flex-wrap">
                    <div class="inline-block col-5">
                        <storefront-icon class="py-3 inline-block" /><span class="ml-3 inline-block py-3">訂單店家</span>
                    </div>
                    <div class="inline-block col-7">
                        <span class="inline-block py-3">{{ shopValue }}</span>
                    </div>
                </div>
                <div class="border-bottom-1 border-gray-200 col-12 lg:col-12 lg:mb-0 flex align-items-center flex-wrap">
                    <div class="inline-block col-5">
                        <i class="py-3 pi pi-calendar-times" /><span class="ml-3 inline-block py-3">指定時間</span>
                    </div>
                    <div class="inline-block col-7">
                        <span class="inline-block py-3">{{ timeValue }}</span>
                    </div>
                </div>
                <div class="border-bottom-1 border-gray-200 col-12 lg:col-12 lg:mb-0 flex align-items-center flex-wrap">
                    <div class="inline-block col-12">
                        <i class="py-3 pi pi-shopping-cart" /><span class="ml-3 inline-block pt-3">訂單內容</span>
                        <div v-if="contentValue['order'].length == 0">訂單目前沒有選擇任何品項喔</div>
                        <div class="ml-2 col-12 bg-gray-50 border-round pb-2 mb-2">
                            <div class="col-12 border-round text-base p-3 mb-3">
                                <span class="inline-block flex align-items-sart justify-content-start"><span>品牌會員： {{
                                    contentValue['brand_phone'] }}</span></span>
                            </div>
                            <span v-for="orderGroup, index in contentValue['order']" v-bind:key="index"
                                class="inline-block col-12">
                                <!-- for each order group -->
                                <div class="inline-block flex align-items-between justify-content-between">
                                    <div class="flex align-items-center justify-content-center bg-gray-200 border-round px-3"
                                        style="height:2.5rem">
                                        <i class="pi text-gray-900">{{ orderGroup['nick'] }}</i>
                                    </div>
                                    <div v-if="pollOwnerValue" v-on:click="removeCartItem(index)"
                                        class="flex align-items-center justify-content-center bg-blue-100 border-round cursor-pointer"
                                        style="width:2.5rem;height:2.5rem">
                                        <i class="pi pi-trash text-blue-500 text-sm"></i>
                                    </div>
                                </div>
                                <div v-for="order, orderIndex in orderGroup['order']" v-bind:key="orderIndex"
                                    class="inline-block flex align-items-center justify-content-center">
                                    <div class="inline-block col-2">
                                        <div class="flex align-items-center justify-content-center border-round"
                                            style="width:2.5rem;height:2.5rem">
                                            <i class="pi text-sm">{{ order.quantity }}x</i>
                                        </div>
                                    </div>
                                    <div class="inline-block col-8 text-sm">{{ order.text }}</div>
                                    <div class="inline-block col-2 text-sm text-right">${{ order.price }}</div>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="border-bottom-1 border-gray-200 col-12 lg:col-12 lg:mb-0 flex align-items-center flex-wrap">
                    <div class="inline-block col-5">
                        <food-icon class="py-3 inline-block" /><span class="ml-3 inline-block py-3">品項數量</span>
                    </div>
                    <div class="inline-block col-7">
                        <span class="inline-block py-3">{{ computedQuantity }}</span>
                    </div>
                </div>
                <div class="border-bottom-1 border-gray-200 col-12 lg:col-12 lg:mb-0 flex align-items-center flex-wrap">
                    <div class="inline-block col-5">
                        <i class="py-3 pi pi-dollar" /><span class="ml-3 inline-block py-3">訂單金額</span>
                    </div>
                    <div class="inline-block col-7">
                        <span class="inline-block py-3">${{ computedTotal }}</span>
                    </div>
                </div>
                <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
                    <Message v-if="shopValue.includes('一沐日') && computedQuantity >= 10" closable="false" severity="success">
                        買10送1、買20送3優惠將由門市直接進行折扣，謝謝</Message>
                    <Message v-if="computedTotal < deliveryMinimumTotalValue" closable="false" severity="warn">訂單金額未達最低消費金額
                        ${{ deliveryMinimumTotalValue }}</Message>
                </div>
                <div v-if="pollOwnerValue && statusValue == 'i' && computedTotal >= deliveryMinimumTotalValue"
                    class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
                    <div class="field-checkbox mb-0">
                        <Checkbox id="checkOption1" name="option" value="agree" v-model="agreeValue" />
                        <label for="checkOption1">我同意訂單一旦確認結帳即無法取消</label>
                    </div>
                </div>
                <div v-if="pollOwnerValue && statusValue == 'i' && computedTotal >= deliveryMinimumTotalValue"
                    class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
                    <Button v-on:click="displaySubmitConfirmation = true" label="送出訂單"
                        class="p-button-secondary p-button mr-2" :disabled="agreeValue != 'agree'" />
                    <Dialog header="送出訂單" v-model:visible="displaySubmitConfirmation" :style="{ width: '350px' }"
                        :modal="true">
                        <div class="flex align-items-center justify-content-center">
                            <span>確定要送出嗎？</span>
                        </div>
                        <template #footer>
                            <Button label="取消" icon="pi pi-times" @click="closeConfirmation" class="p-button-text" />
                            <Button label="確定" icon="pi pi-check" @click="submitForm" class="p-button-text" />
                        </template>
                    </Dialog>
                </div>
                <div v-if="statusValue == 'i'" class="col-12 mb-2 mt-2 lg:col-12 lg:mb-0">
                    <Button v-on:click="purchaseMore" label="追加餐點" class="p-button-secondary p-button mr-2" />
                </div>
                <div v-if="statusValue == 'i'" class="col-12 mb-2 mt-2 lg:col-12 lg:mb-0">
                    <Button v-on:click="modifyInfo" label="修改訂單資訊" class="p-button-secondary p-button mr-2" />
                </div>
                <div class="col-12 mb-2 mt-2 lg:col-12 lg:mb-0">
                    <Button v-on:click="displayNewOrderConfirmation = true" label="開新訂單"
                        class="p-button-secondary p-button mr-2 mt-4" />
                    <Dialog header="開新訂單" v-model:visible="displayNewOrderConfirmation" :style="{ width: '350px' }"
                        :modal="true">
                        <div class="flex align-items-center justify-content-center">
                            <span>確定要開新訂單嗎？(已成立訂單不會因此失效)</span>
                        </div>
                        <template #footer>
                            <Button label="取消" icon="pi pi-times" @click="closeConfirmation" class="p-button-text" />
                            <Button label="確定" icon="pi pi-check" @click="newOrder" class="p-button-text" />
                        </template>
                    </Dialog>
                </div>
            </div>
        </div>
        <!-- 顯示錯誤 -->
        <Dialog position="bottom" header="請修正輸入資料" v-model:visible="displayInvalidPopup" closable="false"
            dismissableMask="true" :breakpoints="{ '960px': '75vw' }" :style="{ width: '30vw' }" :modal="true">
            <p class="line-height-3 m-0">
            <ul id="example-1" class="p-1 m-0">
                <div v-for="invalid in invalidSummary" :key="invalid" class="mt-2">
                    - {{ invalid }}
                </div>
            </ul>
            </p>
            <template #footer>
            </template>
        </Dialog>
    </div>
</template>

<script>
import axios from "axios";
import { useRoute } from "vue-router";

export default {
    data() {
        return {
            statusLogin: 0,
            statusMessage: "",
            statusValue: null,
            /* values */
            orderHashValue: null,
            nameValue: null,
            addressValue: null,
            phoneValue: null,
            companyidValue: null,
            noteValue: null,
            channelValue: null,
            dateValue: null,
            timeValue: null,
            contentValue: null,
            deliveryMinimumTotalValue: null,
            pollOwnerValue: null,
            agreeValue: null,

            originalTime: null,

            computedQuantity: null,
            computedTotal: null,

            displaySubmitConfirmation: false,
            displayNewOrderConfirmation: false,
            displayInvalidPopup: false,
            invalidSummary: [],

            isOrderSubmitted: false,
            isOrderReceived: false,
            isOrderAccepted: false,
            isOrderDelivering: false,
        };
    },
    mounted() {
        this.originalTime = (new Date).getTime();

        this.fetchInfo();
        this.refreshStatus();
    },
    beforeUnmount() {
        clearTimeout(this.timeout);
    },
    created() { },
    methods: {
        fetchInfo() {
            const route = useRoute();
            const hash = route.query.hash;

            this.orderHashValue = hash;

            var params = {
                h: hash,
            };

            axios.defaults.withCredentials = true;
            axios(process.env.VUE_APP_API_BASE_URL + "/s", {
                method: "GET",
                withCredentials: true,
                params: params,
            })
                .then((response) => {
                    this.statusLogin = response.data["code"];
                    if (response.data["code"] == 200) {
                        console.log(response.data);
                        this.channelValue = response.data["channel"];
                        this.nameValue = response.data["name"];
                        this.addressValue = response.data["address"];
                        this.phoneValue = response.data["phone"];
                        this.companyidValue = response.data["companyid"];
                        this.noteValue = response.data["note"];
                        this.shopValue = response.data["shop_name"];
                        this.timeValue = response.data["request_time"];
                        this.contentValue = response.data["content"];
                        this.deliveryMinimumTotalValue = Math.round(response.data["delivery_minimum_total"]);
                        this.pollOwnerValue = response.data["poll_owner"];
                    } else {
                        this.statusMessage = response.data["msg"];
                    }
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        removeCartItem(index) {
            var params = {
                h: this.orderHashValue,
                d: index,
            };

            axios.defaults.withCredentials = true;
            axios(process.env.VUE_APP_API_BASE_URL + "/s", {
                method: "GET",
                withCredentials: true,
                params: params,
            })
                .then((response) => {
                    this.statusSync = response.data["code"];
                    this.statusSyncValue = response.data["msg"];
                    if (this.statusSync == 200 && this.statusSyncValue == 'deleted') {
                        console.log(response.data);
                        this.contentValue['order'].splice(index, 1);
                    }
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        refreshStatus() {
            var params = {
                h: this.orderHashValue,
            };

            axios.defaults.withCredentials = true;
            axios(process.env.VUE_APP_API_BASE_URL + "/s_json", {
                method: "GET",
                withCredentials: true,
                params: params,
            })
                .then((response) => {
                    console.log(response.data);
                    if (response.data["code"] == 200) {
                        this.statusMessage = response.data["msg"];
                        this.statusValue = response.data["status"];
                    }
                    this.timeout = window.setTimeout((() => this.refreshStatus()), 5000);
                })
                .catch((err) => {
                    console.log(err.response);
                    this.timeout = window.setTimeout((() => this.refreshStatus()), 5000);
                });
        },
        purchaseMore() {
            this.$router.push({
                path: "/view", // Entrance
                name: "view",
                query: {
                    h: this.orderHashValue,
                },
            });
        },
        modifyInfo() {
            this.$router.push({
                path: "/register", // Entrance
                name: "register",
                query: {
                    hash: this.orderHashValue,
                },
            });
        },
        submitForm() {
            this.invalidSummary = [];
            var wrongFmt = false;

            if (this.channelValue == '外送') {
                if (this.computedTotal < this.deliveryMinimumTotalValue) {
                    this.invalidSummary.push('訂單金額未達最低消費金額 $' + this.deliveryMinimumTotalValue)
                    wrongFmt = true;
                }
            }

            if (wrongFmt) {
                this.displayInvalidPopup = true;
                return;
            }

            this.closeConfirmation();

            var params = {
                h: this.orderHashValue,
                ot: this.originalTime,
            };

            axios.defaults.withCredentials = true;
            axios(process.env.VUE_APP_API_BASE_URL + "/c", {
                method: "GET",
                withCredentials: true,
                params: params,
            })
                .then((response) => {
                    if (response.data["code"] == 200) {
                        this.$toast.add({ severity: 'success', summary: '訂單送出', detail: response.data["msg"], life: 3000 });
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        console.log(response.data);
                    } else if (response.data["code"] == 405) {
                        alert(response.data["msg"]);
                        this.$router.go(0);
                    }
                })
                .catch((err) => {
                    console.log(err.response);
                });

        },
        newOrder() {
            var q = {
                hash: this.orderHashValue,
            }
            if (this.shopValue.includes('一沐日')) q['b'] = '一沐日';
            if (this.shopValue.includes('一木日')) q['b'] = '一木日';

            console.log("q: " + q);

            this.$router.replace({
                path: "/register", // Entrance
                name: "register",
                query: q,
                params: {
                    n: 'T',
                },
            });
        },
        closeConfirmation() {
            this.displaySubmitConfirmation = false;
            this.displayNewOrderConfirmation = false;
        },
    },
    watch: {
        contentValue: {
            handler: function () {
                if (this.contentValue != null) {
                    var quantity = 0;
                    var total = 0;
                    for (var i = 0; i < this.contentValue['order'].length; i++) {
                        var group = this.contentValue['order'][i];
                        for (var j = 0; j < group['order'].length; j++) {
                            var order = group['order'][j];
                            total += order['price'];
                            if (order['price'] / order['quantity'] > 3) quantity += order['quantity'];
                        }
                    }
                    this.computedQuantity = quantity;
                    this.computedTotal = total;
                }
            },
            deep: true,
        },
        statusValue: {
            handler: function () {
                this.isOrderSubmitted = false;
                this.isOrderReceived = false;
                this.isOrderAccepted = false;
                this.isOrderDelivering = false;
                if (this.statusValue == 's') {
                    this.isOrderSubmitted = true;
                }
                if (this.statusValue == 'a') {
                    this.isOrderReceived = true;
                }
                if (this.statusValue == 'p') {
                    this.isOrderAccepted = true;
                }
                if (this.statusValue == 'm') {
                    this.isOrderDelivering = true;
                }
            }
        }
    },
    computed: {
    },
};
</script>

<style scoped lang="scss">
// 1. Overwrite variables in _variables.scss 
$num-background-active-color: var(--purpply-primary-color);
$num-border-active-color: var(--purpply-primary-color);
$title-active-color: var(--purpply-primary-color);
$text-active-color: var(--purpply-primary-color);
$title-font-size: 1.0em;
$bar-height: 2px;
$bar-border-width: 0px;
$step-width: 70px;

@import 'teb-step-bar/scss/step-bar';

.p-text-purpply {
    color: var(--gray-700);
}

.btn {
    border-radius: 10px;
    border-bottom: 0px;
    display: inline-block;
    padding: 10px;
    position: relative;
    text-align: center;
    transition: background 600ms ease, color 600ms ease;
}

input[type="radio"].toggle {
    display: none;

    &+label {
        cursor: pointer;
        min-width: 120px;
        background-color: var(--purpply-unselected-color);

        &:hover {
            background: none;
            color: var(--purpply-primary-color);
        }

        &:after {
            background-color: var(--purpply-primary-color);
            content: "";
            height: 100%;
            position: absolute;
            top: 0;
            transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
            width: 100%;
            z-index: -1;
        }
    }

    &.toggle-left+label {
        border-right: 0;

        &:after {
            left: 100%;
        }
    }

    &.toggle-right+label {
        margin-left: 0px;

        &:after {
            left: -100%;
        }
    }

    &:checked+label {
        cursor: default;
        color: #fff;
        background-color: var(--purpply-primary-color);
        transition: color 200ms;

        &:after {
            left: 0;
        }
    }
}
</style>
